export class ABTestInfoModel {
    public Subject: string;
    public Subject1: string;
    public ListId: number;
    public SecondListId: number;
    public ContactsFilter: string;
    public SecondListContactsFilter: string;
    public ABTestLength: number;
    public ABTestLengthType: number;
    public ABTestSize: number;
    public SubjectAContactsCount: number;
    public SubjectBContactsCount: number;
    public WinningContactsCount: number;
    public SecondListSubjectAContactsCount: number;
    public SecondListSubjectBContactsCount: number;
    public SecondListWinningContactsCount: number;
  
    constructor(init: any) {
      this.Subject = init.Subject;
      this.Subject1 = init.Subject1;
      this.ListId = init.ListId;
      this.SecondListId = init.SecondListId;
      this.ContactsFilter = init.ContactsFilter;
      this.SecondListContactsFilter = init.SecondListContactsFilter;
      this.ABTestLength = init.ABTestLength;
      this.ABTestLengthType = init.ABTestLengthType;
      this.ABTestSize = init.ABTestSize;
      this.SubjectAContactsCount = init.SubjectAContactsCount;
      this.SubjectBContactsCount = init.SubjectBContactsCount;
      this.WinningContactsCount = init.WinningContactsCount;
      this.SecondListSubjectAContactsCount = init.SecondListSubjectAContactsCount;
      this.SecondListSubjectBContactsCount = init.SecondListSubjectBContactsCount;
      this.SecondListWinningContactsCount = init.SecondListWinningContactsCount;
    }
  }