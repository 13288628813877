import { Injectable } from "@angular/core";
import { ABTestInfoModel } from "./ab-test-info-model";

@Injectable({
    providedIn: 'root'
  })
  
  export class ABTestInfoModelAdapter {
    adapt(item: any): ABTestInfoModel {
      return new ABTestInfoModel(item);
    }
  
    createEmpty() {
      return new ABTestInfoModel({});
    }
  }