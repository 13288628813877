<div class="email-sms-report-details" #pdfContainer>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="navigation" (click)="goBack()">
          <img class="image-back" src="../../../../../assets/images/back.png" alt="Back Button">
          <span class="label-back">
            {{ 'Back to ' + (messageType === messageTypeEnumEmail ? 'Email' : 'SMS') + ' Report' }}
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="title-box">
          <span class="header-section">
            {{ messageType === messageTypeEnumEmail ? emailReport.MessageName : smsReport.MessageName}}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12">
    <!--Card-->
    <mdb-card [ngClass]="{'unset-shadow': downloadingPDF}" class="mt-5">
      <!--Card Content-->
      <mdb-card-body cascade="true" class="row header-container">
        <div class="col-md-3 d-flex pr-0">
          <div class="pr-1">Subject:</div>
          <div class="info-text">
            {{ messageType === messageTypeEnumEmail ? emailReport.Subject : smsReport.MessageName }}
          </div>
        </div>

        <div class="col-md-3 d-flex px-0">
          <div class="pr-1">List:</div>
          <div class="info-text">{{ listName }}</div>
        </div>

        <div class="col-md-3 d-flex pl-0">
          <div class="pr-1">Last Sent Date:</div>
          <div class="info-text">
            {{ (messageType === messageTypeEnumEmail ? emailReport.LastSentDate : smsReport.SendDate) | date: 'dd/MM/yyyy H:mm a' }}
          </div>
        </div>

        <div class="col-md-3 button-section my-3 pl-0">
          <button *ngIf="messageType === messageTypeEnumEmail"
                  type="button" class="button-header-panel"
                  [routerLink]="['/messages/email-preview', emailReport.MessageID]">
            Preview Message
          </button>
          <button type="button" (click)="downloadAsPDF()"
                  class="button-header-panel"
                  [ngClass]="{'ml-auto': messageType !== messageTypeEnumEmail}">
            Download PDF
          </button>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>


  <div class="col-md-12">
    <mdb-card [ngClass]="{'unset-shadow':downloadingPDF}" cascade="true" class="mt-5">
      <mdb-card-body cascade="true" class="text-center top-card-body py-0 px-3">
        <div class="d-flex align-items-center py-2 title-table-section border-bottom">
          Quick Stats
        </div>

        <div class="row pr-3">
          <div [ngClass]="messageType === messageTypeEnumEmail ? 'col-md-12' : 'col-md-8'">
            <div *ngIf="messageType === messageTypeEnumEmail">
              <div class="row py-4 px-2">
                <div (click)="drillDownReport('EmailTotalRecipients')" class="col-md-2">
                  <div class="pb-2">Total Recipients</div>
                  <div class="stat-number">{{ emailReport.TotalRecipients }}</div>
                </div>

                <div (click)="drillDownReport('EmailDelivered')" class="col-md-2">
                  <div class="pb-2">Delivered</div>
                  <div class="stat-number">{{ emailReport.Delivered }}</div>
                </div>

                <div (click)="drillDownReport('EmailFailed')" class="col-md-2">
                  <div class="pb-2">Failed</div>
                  <div class="stat-number">{{ emailReport.Failed }}</div>
                </div>

                <div (click)="drillDownReport('EmailBounced')" class="col-md-2">
                  <div class="pb-2">Bounced</div>
                  <div class="stat-number">{{ emailReport.Bounced }}</div>
                </div>

                <div (click)="drillDownReport('EmailTemporaryBounced')" class="col-md-2">
                  <div class="pb-2">Temporary Bounced</div>
                  <div class="stat-number">{{ emailReport.TemporaryBounced }}</div>
                </div>

                <div (click)="drillDownReport('EmailOpened')" class="col-md-2">
                  <div class="pb-2">Opened</div>
                  <div class="stat-number">{{ emailReport.Opened }}</div>
                </div>
              </div>

              <div class="row px-2 py-0">
                <div (click)="drillDownReport('EmailUnopened')" class="col-md-2">
                  <div class="pb-2">Unopened</div>
                  <div class="stat-number">{{ emailReport.Unopened }}</div>
                </div>

                <div (click)="drillDownReport('EmailClicked')" class="col-md-2">
                  <div class="pb-2">Clicked</div>
                  <div class="stat-number">{{ emailReport.Clicked }}</div>
                </div>

                <div (click)="drillDownReport('EmailProfileUpdated')" class="col-md-2">
                  <div class="pb-2">Profile Updated</div>
                  <div class="stat-number">{{ emailReport.ProfileUpdated }}</div>
                </div>

                <div (click)="drillDownReport('EmailComplained')" class="col-md-2">
                  <div class="pb-2">Complained</div>
                  <div class="stat-number">{{this.emailReport.Complained}}</div>
                </div>

                <div (click)="drillDownReport('EmailUnsubscribed')" class="col-md-2">
                  <div class="pb-2">Unsubscribed</div>
                  <div class="stat-number">{{ emailReport.Unsubscribed }}</div>
                </div>
              </div>
            </div>

            <div *ngIf="messageType === messageTypeEnumSMS" class="row">
              <div class="col-md-12">
                <div class="row py-4 px-2">
                  <div (click)="drillDownReport('SmsTotalRecipients')" class="col-md-3">
                    <div class="pb-2">Total Sent</div>
                    <div class="stat-number">{{ smsReport.TotalSent }}</div>
                  </div>

                  <div (click)="drillDownReport('SmsDelivered')" class="col-md-3">
                    <div class="pb-2">Delivered</div>
                    <div class="stat-number">{{ smsReport.ActivityDelivered }}</div>
                  </div>

                  <div (click)="drillDownReport('SmsUndelivered')" class="col-md-3">
                    <div class="pb-2">Undelivered</div>
                    <div class="stat-number">{{ smsReport.ActivityUndelivered }}</div>
                  </div>

                  <div (click)="drillDownReport('SmsPending')" class="col-md-3">
                    <div class="pb-2">Pending</div>
                    <div class="stat-number">{{ smsReport.ActivityPending }}</div>
                  </div>
                </div>

                <div class="row py-0">
                  <div (click)="drillDownReport('SmsBounced')" class="col-md-3">
                    <div class="pb-2">Bounced</div>
                    <div class="stat-number">{{ smsReport.ActivityBounced }}</div>
                  </div>

                  <div (click)="drillDownReport('SmsTotalReplies')" class="col-md-3">
                    <div class="pb-2">Total Replies</div>
                    <div class="stat-number">{{ smsReport.ActivityTotalReplies }}</div>
                  </div>

                  <div (click)="drillDownReport('SmsTotalUnique')" class="col-md-3">
                    <div class="pb-2">Total Unique Replies</div>
                    <div class="stat-number">{{ smsReport.ActivityTotalUniqueReplies }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row pt-5 pb-4 px-3">
              <div *ngIf="messageType === messageTypeEnumEmail" class="col-md-4 px-1">
                <mdb-card class="chart-container" cascade="true">
                  <mdb-card-body cascade="true" class="text-center px-0">
                    <div class="mt-2">Delivered</div>
                    <div class="container-fluid px-0 mb-2">
                      <div class="d-block">
                        <canvas mdbChart
                                [chartType]="deliveredChartType"
                                [datasets]="deliveredChartDatasets"
                                [labels]="deliveredChartLabels"
                                [colors]="deliveredChartColors"
                                [options]="deliveredChartOptions"
                                [legend]="true">
                        </canvas>
                      </div>
                    </div>
                  </mdb-card-body>
                </mdb-card>
              </div>

              <div class="col-md-4 px-1">
                <mdb-card class="chart-container" cascade="true" [ngClass]="{'chart-width': messageType === messageTypeEnumSMS}">
                  <mdb-card-body cascade="true" class="text-center px-0">
                    <div class="mt-2">Opened</div>
                    <div class="container-fluid px-0 mb-2">
                      <div *ngIf="messageType === messageTypeEnumEmail" class="d-block">
                        <canvas mdbChart
                                [chartType]="emailOpenedChartType"
                                [datasets]="emailOpenedChartDatasets"
                                [labels]="emailOpenedChartLabels"
                                [colors]="emailOpenedChartColors"
                                [options]="emailOpenedChartOptions"
                                [legend]="true">
                        </canvas>
                      </div>
                      <div *ngIf="messageType === messageTypeEnumSMS" class="d-block">
                        <canvas mdbChart
                                [chartType]="smsOpenedChartType"
                                [datasets]="smsOpenedChartDatasets"
                                [labels]="smsOpenedChartLabels"
                                [colors]="smsOpenedChartColors"
                                [options]="smsOpenedChartOptions"
                                [legend]="true">
                        </canvas>
                      </div>
                    </div>
                  </mdb-card-body>
                </mdb-card>
              </div>

              <div *ngIf="messageType === messageTypeEnumEmail" class="col-md-4 px-1">
                <mdb-card class="chart-container" cascade="true">
                  <mdb-card-body cascade="true" class="text-center px-0">
                    <div class="mt-2">Clicked</div>
                    <div class="container-fluid px-0 mb-2">
                      <div class="d-block">
                        <canvas mdbChart
                                [chartType]="clickedChartType"
                                [datasets]="clickedChartDatasets"
                                [labels]="clickedChartLabels"
                                [colors]="clickedChartColors"
                                [options]="clickedChartOptions"
                                [legend]="true">
                        </canvas>
                      </div>
                    </div>
                  </mdb-card-body>
                </mdb-card>
              </div>
            </div>
          </div>

          <mdb-card *ngIf="messageType === messageTypeEnumSMS" class="col-md-4 phone-container my-4 px-0">
            <div class="iphone">
              <div class="border">
                <form class="chat">
                  <span></span>
                  <div class="messages" id="adobewordpress">
                    <div class="message">
                      <div class="fromThem">
                        <p>{{ smsReport.MessageBody }}</p>
                        <date><b></b>{{ smsReport.SendDate | date: 'dd/MM/yyyy H:mm a' }}</date>
                      </div>
                    </div>
                  </div>
                  <input type="text" placeholder="Your message" class="pr-0">
                  <input type="submit" value="Send">
                </form>
              </div>
            </div>
          </mdb-card>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>

  <div class="mt-2 mb-1" *ngIf="showABTestBlock">
    <div class="col-md-12">
      <div class="container ab-container">
        <h1 class="ab-title">A/B Testing</h1>
        <div class="grid ab-grid">

          <div class="card ab-card-winner">
            <h2 class="ab-subtitle">Test Winner</h2>
            <div *ngIf="this.abTestReport.WinnerSubject" class="ab-circle dark">{{this.abTestReport.WinnerSubject == this.abTestReport.SubjectA ? 'A' : 'B' }}</div>
            <p *ngIf="!this.abTestReport.WinnerSubject">Will be shown once the test activity is complete.</p>
          </div>

          <div class="card ab-card">            
            <div class="ab-stats">
              <div>
                <div class="ab-circle dark">A</div>
                <p>{{this.abTestReport.SubjectATotalOpenPercent}}%</p>
                <p>{{this.abTestReport.SubjectATotalOpenCount}}/{{this.abTestReport.SubjectATotalSent}}</p>
              </div>
              <div>
                <h2 class="ab-subtitle">Opened</h2>
              </div>
              <div>
                <div class="ab-circle light">B</div>
                <p>{{this.abTestReport.SubjectBTotalOpenPercent}}%</p>
                <p>{{this.abTestReport.SubjectBTotalOpenCount}}/{{this.abTestReport.SubjectBTotalSent}}</p>
              </div>
            </div>
          </div>

          <div class="card ab-card">            
            <div class="ab-stats">
              <div>
                <center>
                  <div class="ab-circle dark">A</div>
                  <p>{{this.abTestReport.SubjectA}}</p>
                </center>
              </div>
              <div>
                <h2 class="ab-subtitle">Subject</h2>
              </div>
              <div>
                <center>
                  <div class="ab-circle light">B</div>
                  <p>{{this.abTestReport.SubjectB}}</p>
                </center>
              </div>
            </div>
          </div>

          <div class="card ab-card">            
            <div class="ab-stats">
              <div>
                <div class="ab-circle dark">A</div>
                <p *ngIf="this.abTestReport.WinnerSubject">{{this.abTestReport.SubjectATotalSentPercent}}%</p>
                <p>{{this.abTestReport.SubjectATotalSent}}</p>
              </div>
              <div>
                <h2 class="ab-subtitle">Sent</h2>
              </div>
              <div>
                <div class="ab-circle light">B</div>
                <p *ngIf="this.abTestReport.WinnerSubject">{{this.abTestReport.SubjectBTotalSentPercent}}%</p>
                <p>{{this.abTestReport.SubjectBTotalSent}}</p>
              </div>
            </div>
          </div>

        </div>
      </div>    
    </div>
  </div>

  <div class="mt-2 mb-1">
    <div class="col-md-12">
      <app-table [tableKey]="messageType === messageTypeEnumEmail ? 'email' : 'sms'"
                 [displayedColumns]="displayedColumns"
                 [showActionSection]="false"
                 [data]="data"
                 [length]="length"
                 (tableData)="getData($event)"
                 [ownerPage]="'linkclicks'">
      </app-table>
    </div>
  </div>

</div>
