export class MessageSendDtoRequest {
    messageId: number;
    listId: number;
    contactsFilter: string;
    IsRegularEmailSending: boolean;
    Subject: string;
    Subject1: string;
    ABTestLength: number;
    ABTestLengthType: number;
    ABTestSize: number;
    public SubjectAContactsCount: number;
    public SubjectBContactsCount: number;
    public WinningContactsCount: number;

    constructor(init: { messageId: number, listId: number, contactsFilter: string, IsRegularEmailSending: boolean,  
                        Subject: string, Subject1: string, ABTestLength: number, ABTestLengthType: number, ABTestSize: number,
                        SubjectAContactsCount: number,  SubjectBContactsCount: number, WinningContactsCount: number}) {
        this.messageId = init.messageId;
        this.listId = init.listId;
        this.contactsFilter = init.contactsFilter;
        this.IsRegularEmailSending = init.IsRegularEmailSending;
        this.Subject = init.Subject;
        this.Subject1 = init.Subject1;
        this.ABTestLength = init.ABTestLength;
        this.ABTestLengthType = init.ABTestLengthType;
        this.ABTestSize = init.ABTestSize;
        this.SubjectAContactsCount = init.SubjectAContactsCount;
        this.SubjectBContactsCount = init.SubjectBContactsCount;
        this.WinningContactsCount = init.WinningContactsCount;
    }
}
