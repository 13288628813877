import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { DataTableColumn } from 'src/app/_models/messaging/datatable-column';
import { PaginationDataService } from 'src/app/_services/tables/pagination-data/pagination-data.service';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { HelperService } from 'src/app/_services/system/helpers/helper.service';
import { SearchContactDtoRequest } from 'src/app/_models/messaging/lists-and-contacts/contacts/search-contact-dto-request';
import { ContactService } from 'src/app/_services/messaging/lists-and-contacts/contacts/contact.service';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { NotificationService } from 'src/app/_services/notification.service';
import { DatePipe } from '@angular/common';
import { QueryBuilderStorageService } from 'src/app/_services/query-builder-filters/query-builder-storage.service';
import { LoadingSpinnerService } from '../../../../../_services/loading-spinner/loading-spinner.service';
import { GetFinalContactsDtoRequest } from 'src/app/_models/messaging/lists-and-contacts/contacts/get-final-contacts-dto-request';

@Component({
  selector: 'app-preview-data-message-multi-list',
  templateUrl: './preview-data-message-multi-list.component.html',
  styleUrls: ['./preview-data-message-multi-list.component.scss']
})
export class PreviewDataMessageMultiListComponent implements OnInit {

  //Modal and exteral Inputs from Parent Component
  @ViewChild('contactPreviewModal', { static: true }) contactPreviewModal: ModalDirective;
  @Input() parentStepForm: UntypedFormGroup;
  @Input() listFolder: number;
  @Input() secondListFolder: number;
  @Input() listID: number;
  @Input() filterContactRules: string = '';
  @Input() secondListID: number;
  @Input() secondListFilterContactRules: string = '';
  @Input() messageID: number;
  @Input() listName: string = '';
  @Input() secondListName: string = '';

  //Variables for the table
  _tableHasRows = false;
  _secondTableHasRows = false;
  _finalContactsTableHasRows = false;

  _allColumns: DataTableColumn[] = [];
  _columns: DataTableColumn[] = [];

  _secondTableAllColumns: DataTableColumn[] = [];
  _secondTableColumns: DataTableColumn[] = [];

  _finalContactsTableAllColumns: DataTableColumn[] = [];
  _finalContactsTableColumns: DataTableColumn[] = [];

  _columnMode = ColumnMode;

  _rows: { [prop: string]: any }[] = [];
  _secondTableRows: { [prop: string]: any }[] = [];
  _finalContactsTableRows: { [prop: string]: any }[] = [];

  //Default column sorting value variables
  _sortColumn: string = 'UpdatedDate';
  _sortDirection: string = 'Descending';

  //Pagination
  _pageSizeOptions: number[] = [10, 25, 50, 100];
  _currentPage = 1;
  _pageCount = 0;
  _pageLimit = 10;
  _pageOffset = 0;

  _pageTitle = 'Contacts Preview';
  _secondTablePageTitle = 'Contacts Preview Second List';
  _finalContactsTableTitle = 'Final Contacts Preview';

   _secondTableSortColumn: string = 'UpdatedDate';
  _secondTableSortDirection: string = 'Descending';
  _secondTablePageSizeOptions: number[] = [10, 25, 50, 100];
  _secondTableCurrentPage = 1;
  _secondTablePageCount = 0;
  _secondTablePageLimit = 10;
  _secondTablePageOffset = 0;

  _finalContactsTableSortColumn: string = 'UpdatedDate';
  _finalContactsTableSortDirection: string = 'Descending';
  _finalContactsTablePageSizeOptions: number[] = [10, 25, 50, 100];
  _finalContactsTableCurrentPage = 1;
  _finalContactsTablePageCount = 0;
  _finalContactsTablePageLimit = 10;
  _finalContactsTablePageOffset = 0;

  //Exports
  _exportRows: { [prop: string]: any }[] = [];
  _exporting = false;

  //General Page Vars
  _isEmailMessageType = true;
  _selectedTabIndex = 0;

  constructor(
    private paginationDataService: PaginationDataService,
    private contactService: ContactService,
    private helperService: HelperService,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private queryBuilderStorageService: QueryBuilderStorageService,
    private loadingSpinnerService: LoadingSpinnerService,
  ) { }

  ngOnInit() {
    this.onParentalControlChanges();
  }

  // Subscribe to any parent control changes
  onParentalControlChanges() {
    var contactFilterCriteriaFC = this.parentStepForm.controls.listForm.get('contactFilterCriteria');
    var secondListContactFilterCriteriaFC = this.parentStepForm.controls.listForm.get('secondListContactFilterCriteria');

    //Check to see if the message type is Email or SMS and disable based on button
    var messageType = this.parentStepForm.controls.messageForm.get('messageType').value;
    if (messageType == 1 || messageType == 3) {
      this._isEmailMessageType = true;
    } else {
      this._isEmailMessageType = false;
    }

    //Get current filter contact rules value
    this.filterContactRules = contactFilterCriteriaFC.value != null ? contactFilterCriteriaFC.value : '';
    this.secondListFilterContactRules =  secondListContactFilterCriteriaFC.value != null ? secondListContactFilterCriteriaFC.value : '';

    //Subscribe and Populate Lists for Contacts Export and Get Contacts for Preview
    this.parentStepForm.controls.listForm.get('list').valueChanges.subscribe(val => {
      if (this.listID) {
        this.listID = val;
      }
    });

    this.parentStepForm.controls.listForm.get('secondList').valueChanges.subscribe(val => {
      if (this.secondListID) {
        this.secondListID = val;
      }
    });

    //Update List if Sql filter has changed
    contactFilterCriteriaFC.valueChanges.subscribe(val => {
      if (this.listID) {
        this.filterContactRules = val;
      }
    });

    secondListContactFilterCriteriaFC.valueChanges.subscribe(val => {
      if (this.secondListID) {
        this.secondListFilterContactRules = val;
      }
    });

    //Disable Preview Email button if sendMessageType is SMS
    this.parentStepForm.controls.messageForm.get('messageType').valueChanges.subscribe(val => {
      if (val == 1 || val == 3) this._isEmailMessageType = true;
      else this._isEmailMessageType = false;
    });

    //Subscribe to Message ID value to ensure right email is being previewed
    this.parentStepForm.controls.messageForm.get('message').valueChanges.subscribe(val => this.messageID = val);
  }

  // Preview Email in New Tab
  previewEmail() {
    //Save Sql to local storage
    this.queryBuilderStorageService.SetSQLFilterToLocalStorage(this.filterContactRules);

    //Ensure message is selected before showing them the preview page and open url in new tab
    if (!this.messageID) this.notificationService.showWarning("Please ensure you have selected an email");
    else window.open(`${window.location.protocol}//${window.location.host}/messages/email-preview/${this.messageID}/${this.listFolder}/${this.listID}`, "_blank");
  }

  previewEmailForSecondList() {
    //Save Sql to local storage
    this.queryBuilderStorageService.SetSQLFilterToLocalStorage(this.secondListFilterContactRules);

    //Ensure message is selected before showing them the preview page and open url in new tab
    if (!this.messageID) this.notificationService.showWarning("Please ensure you have selected an email");
    else window.open(`${window.location.protocol}//${window.location.host}/messages/email-preview/${this.messageID}/${this.secondListFolder}/${this.secondListID}`, "_blank");
  }

  // Contacts Modal & Table Functions

  viewContactsModal() {
    //Populate List for Contacts Export and Get Contacts for Preview First Time
    if (!this.listID || !this.secondListID) this.notificationService.showWarning("You havent selected a List");
    else {
      this.contactPreviewModal.show();
    }
  }

  //Upon Modal open, populate List and Get Contacts
  onOpened(event: any) {
    this.paginationDataService.ClearChildModelDataFromLocalStorage();
    this.paginationDataService.ClearSecondListChildModelDataFromLocalStorage();
    this.paginationDataService.ClearFinalContactsListChildModelDataFromLocalStorage();

    this.getFinalContacts();
    this.getContacts();
    this.getContactsForSecondList();    
  }


  // optional variables that allow dynamic sorting as data is retrieved from the database
  // rather than all stored client side
  getContacts(pageInfo?: any, sortDirection?: string, sortColumn?: string) {
    this.loadingSpinnerService.loading();

    if (sortDirection !== undefined) this._sortDirection = sortDirection;
    if (sortColumn !== undefined) this._sortColumn = sortColumn;
    var pageNumber = pageInfo ? pageInfo : 1;

    if (pageInfo) this.paginationDataService.childModelPaginationData.page = pageNumber;
    else if (this.paginationDataService.childModelPaginationData.page) pageNumber = this.paginationDataService.childModelPaginationData.page;

    this._currentPage = pageNumber;

    const pagerDtoRequest = new PagerDtoRequest({
      page: this._currentPage,
      pageSize: this._pageLimit,
      sortColumn: this._sortColumn,
      sortDirection: this._sortDirection
    });

    var consentfilter = this._isEmailMessageType ? 'ISNULL(Consent_Email, 1) = 1 AND Bounced = 0' : 'ISNULL(Consent_SMS, 1) = 1';
    var filterContactRules = '';

    if (this.filterContactRules.indexOf(consentfilter) === -1)  {
      filterContactRules = this.filterContactRules.length === 0 ? consentfilter : this.filterContactRules + ' AND (' + consentfilter + ')'
    } 

    const getContactsPagerDtoRequest = new SearchContactDtoRequest({
      pagerDtoRequest: pagerDtoRequest,
      listId: this.listID,
      contactFilter: filterContactRules
    });

    this.paginationDataService.SetChildModelPaginationDataToLocalStorage(pageNumber, this._pageLimit, this._sortColumn, this._sortDirection, this._pageTitle);

    this.contactService.getContacts(
        getContactsPagerDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this._pageCount = executionResultDto.data.Total;
          this._pageOffset = pageNumber - 1;

          this._tableHasRows = this._pageCount > 0;

          this._allColumns = this._columns = this._rows = [];

          if (!this._tableHasRows) {
            this.loadingSpinnerService.stopLoading();
            return;
          }

          this._allColumns = executionResultDto.data.Columns.map(column =>
            new DataTableColumn({
              prop: column.Name,
              name: column.DisplayName.replace(/([a-z])([A-Z])/g, '$1 $2'),
              visible: true
          }));

          this._columns = this._allColumns;

          this._rows = this.getRows(executionResultDto);

        } else {
          this.loadingSpinnerService.stopLoading();
          this.notificationService.showError(executionResultDto.message);
        }

        this.loadingSpinnerService.stopLoading();
    });
  }

  getContactsForSecondList(pageInfo?: any, sortDirection?: string, sortColumn?: string) {
    this.loadingSpinnerService.loading();

    if (sortDirection !== undefined) this._secondTableSortDirection = sortDirection;
    if (sortColumn !== undefined) this._secondTableSortColumn = sortColumn;
    var pageNumber = pageInfo ? pageInfo : 1;

    if (pageInfo) this.paginationDataService.secondListChildModelPaginationData.page = pageNumber;
    else if (this.paginationDataService.secondListChildModelPaginationData.page) pageNumber = this.paginationDataService.secondListChildModelPaginationData.page;

    this._secondTableCurrentPage = pageNumber;

    const pagerDtoRequest = new PagerDtoRequest({
      page: this._secondTableCurrentPage,
      pageSize: this._secondTablePageLimit,
      sortColumn: this._secondTableSortColumn,
      sortDirection: this._secondTableSortDirection
    });

    var consentfilter = this._isEmailMessageType ? 'ISNULL(Consent_Email, 1) = 1 AND Bounced = 0' : 'ISNULL(Consent_SMS, 1) = 1';
    var secondListFilterContactRules = '';

    if (this.secondListFilterContactRules.indexOf(consentfilter) === -1)  {
      secondListFilterContactRules = this.secondListFilterContactRules.length === 0 ? consentfilter : this.secondListFilterContactRules + ' AND (' + consentfilter + ')'
    } 

    const getContactsPagerDtoRequest = new SearchContactDtoRequest({
      pagerDtoRequest: pagerDtoRequest,
      listId: this.secondListID,
      contactFilter: secondListFilterContactRules
    });

    this.paginationDataService.SetSecondListChildModelPaginationDataToLocalStorage(pageNumber, this._secondTablePageLimit, this._secondTableSortColumn, this._secondTableSortDirection, this._secondTablePageTitle);

    this.contactService.getContacts(
        getContactsPagerDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this._secondTablePageCount = executionResultDto.data.Total;
          this._secondTablePageOffset = pageNumber - 1;

          this._secondTableHasRows = executionResultDto.data.Total > 0;

          this._secondTableAllColumns = this._secondTableColumns = this._secondTableRows = [];

          if (!this._secondTableHasRows) {
            this.loadingSpinnerService.stopLoading();
            return;
          }

          this._secondTableAllColumns = executionResultDto.data.Columns.map(column =>
            new DataTableColumn({
              prop: column.Name,
              name: column.DisplayName.replace(/([a-z])([A-Z])/g, '$1 $2'),
              visible: true
          }));

          this._secondTableColumns = this._secondTableAllColumns;

          this._secondTableRows = this.getRows(executionResultDto);

        } else {
          this.loadingSpinnerService.stopLoading();
          this.notificationService.showError(executionResultDto.message);
        }

        this.loadingSpinnerService.stopLoading();
    });
  }

  getFinalContacts(pageInfo?: any, sortDirection?: string, sortColumn?: string) {
    this.loadingSpinnerService.loading();

    if (sortDirection !== undefined) this._finalContactsTableSortDirection = sortDirection;
    if (sortColumn !== undefined) this._finalContactsTableSortColumn = sortColumn;
    var pageNumber = pageInfo ? pageInfo : 1;

    if (pageInfo) this.paginationDataService.finalContactsListChildModelPaginationData.page = pageNumber;
    else if (this.paginationDataService.finalContactsListChildModelPaginationData.page) pageNumber = this.paginationDataService.finalContactsListChildModelPaginationData.page;

    this._finalContactsTableCurrentPage = pageNumber;

    const pagerDtoRequest = new PagerDtoRequest({
      page: this._finalContactsTableCurrentPage,
      pageSize: this._finalContactsTablePageLimit,
      sortColumn: this._finalContactsTableSortColumn,
      sortDirection: this._finalContactsTableSortDirection
    });

    var consentfilterForFinalContacts = this._isEmailMessageType ? 'ISNULL(CASE WHEN ce.[Email] IS NULL THEN c.[Consent_Email] ELSE ce.[Consent_Email] END, 1) = 1 AND ce.[Bounced] = 0' : 'ISNULL(Consent_SMS, 1) = 1';
    var filterContactRules = '';
    var secondListFilterContactRules = '';

    if (this.filterContactRules.indexOf(consentfilterForFinalContacts) === -1)  {
      filterContactRules = this.filterContactRules.length === 0 ? consentfilterForFinalContacts : this.filterContactRules + ' AND (' + consentfilterForFinalContacts + ')'
    } 

    if (this.secondListFilterContactRules.indexOf(consentfilterForFinalContacts) === -1)  {
      secondListFilterContactRules = this.secondListFilterContactRules.length === 0 ? consentfilterForFinalContacts : this.secondListFilterContactRules + ' AND (' + consentfilterForFinalContacts + ')'
    } 
    
    const getFinalContactsDtoRequest = new GetFinalContactsDtoRequest({
      pagerDtoRequest: pagerDtoRequest,
      listId: this.listID,
      contactFilter: filterContactRules,
      secondListId: this.secondListID,
      secondListContactFilter: secondListFilterContactRules,
      isEmail: this._isEmailMessageType
    });

    this.paginationDataService.SetFinalContactsListChildModelPaginationDataToLocalStorage(pageNumber, this._finalContactsTablePageLimit, this._finalContactsTableSortColumn, this._finalContactsTableSortDirection, this._finalContactsTableTitle);
   
    this.contactService.getFinalContactsFromTwoLists(
      getFinalContactsDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this._finalContactsTablePageCount = executionResultDto.data.Total;
          this._finalContactsTablePageOffset = pageNumber - 1;

          this._finalContactsTableHasRows = executionResultDto.data.Total > 0;

          this._finalContactsTableAllColumns = this._finalContactsTableColumns = this._finalContactsTableRows = [];

          if (!this._finalContactsTableHasRows) {
            this.loadingSpinnerService.stopLoading();
            return;
          }

          this._finalContactsTableAllColumns = executionResultDto.data.Columns.map(column =>
            new DataTableColumn({
              prop: column.Name,
              name: column.DisplayName.replace(/([a-z])([A-Z])/g, '$1 $2'),
              visible: true
          }));

          this._finalContactsTableColumns = this._finalContactsTableAllColumns;

          this._finalContactsTableRows = this.getRows(executionResultDto);

        } else {
          this.loadingSpinnerService.stopLoading();
          this.notificationService.showError(executionResultDto.message);
        }

        this.loadingSpinnerService.stopLoading();
    });
  }

   // Export Contacts
  export() {
    if (this._selectedTabIndex == 0)
      this.exportFinalContacts();
    else
      this.exportLists();    
  }

  exportFinalContacts() {

    const pagerDtoRequest = new PagerDtoRequest({
      page: 1,
      pageSize: 1000000,
      sortColumn: this._finalContactsTableSortColumn,
      sortDirection: this._finalContactsTableSortDirection
    });

    var consentfilterForFinalContacts = this._isEmailMessageType ? 'ISNULL(CASE WHEN ce.[Email] IS NULL THEN c.[Consent_Email] ELSE ce.[Consent_Email] END, 1) = 1 AND ce.[Bounced] = 0' : 'ISNULL(Consent_SMS, 1) = 1';
    var filterContactRules = '';
    var secondListFilterContactRules = '';

    if (this.filterContactRules.indexOf(consentfilterForFinalContacts) === -1)  {
      filterContactRules = this.filterContactRules.length === 0 ? consentfilterForFinalContacts : ' AND (' + consentfilterForFinalContacts + ')'
    } 

    if (this.secondListFilterContactRules.indexOf(consentfilterForFinalContacts) === -1)  {
      secondListFilterContactRules = this.secondListFilterContactRules.length === 0 ? consentfilterForFinalContacts : ' AND (' + consentfilterForFinalContacts + ')'
    }

    const getFinalContactsDtoRequest = new GetFinalContactsDtoRequest({
      pagerDtoRequest: pagerDtoRequest,
      listId: this.listID,
      contactFilter: filterContactRules,
      secondListId: this.secondListID,
      secondListContactFilter: secondListFilterContactRules,
      isEmail: this._isEmailMessageType
    });

    this._exporting = true;

    this.contactService.getFinalContactsFromTwoLists(getFinalContactsDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {

        this._exportRows = this.getFinalContactsTableRowsForExport(executionResultDto);

        const listName = 'Final Contacts';

        const today = new Date();
        const fileName = `${listName}_${today.getDate()}${today.getMonth() + 1}${today.getFullYear()}`;

        this.helperService.exportToCSV(fileName, this._exportRows);

      } else {
        this.notificationService.showError(executionResultDto.message);
        return;
      }
      this._exporting = false;
    });

  }

  exportLists() {

    const pagerDtoRequest = new PagerDtoRequest({
      page: 1,
      pageSize: 1000000,
      sortColumn: 'UpdatedDate',
      sortDirection: 'DESC'
    });

    var consentfilter = this._isEmailMessageType ? 'ISNULL(Consent_Email, 1) = 1 AND Bounced = 0' : 'ISNULL(Consent_SMS, 1) = 1';
    var filterContactRules = '';
    var secondListFilterContactRules = '';

    if (this.filterContactRules.indexOf(consentfilter) === -1)  {
      filterContactRules = this.filterContactRules.length === 0 ? consentfilter : ' AND (' + consentfilter + ')'
    } 

    if (this.secondListFilterContactRules.indexOf(consentfilter) === -1)  {
      secondListFilterContactRules = this.secondListFilterContactRules.length === 0 ? consentfilter : ' AND (' + consentfilter + ')'
    } 

    const getContactsPagerDtoRequest = new SearchContactDtoRequest({
      pagerDtoRequest: pagerDtoRequest,
      listId: this._selectedTabIndex == 1 ? this.listID : this.secondListID,
      contactFilter: this._selectedTabIndex == 1 ? filterContactRules : secondListFilterContactRules
    });

    this._exporting = true;

    this.contactService.getContacts(getContactsPagerDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {

        this._exportRows = this._selectedTabIndex == 1 ? this.getRowsForExport(executionResultDto) : this.getSecondTableRowsForExport(executionResultDto);

        const listName = this._selectedTabIndex == 1 ? this.listName 
                                                      : this.secondListName;
        const today = new Date();
        const fileName = `${listName}_${today.getDate()}${today.getMonth() + 1}${today.getFullYear()}`;

        this.helperService.exportToCSV(fileName, this._exportRows);

      } else {
        this.notificationService.showError(executionResultDto.message);
        return;
      }
      this._exporting = false;
    });

  }


  // Get Rows to display Contacts
  getRows(executionResultDto: ExecutionResultDto) {
    let rows: { [prop: string]: any }[] = [];
    let dateTimeColumns = executionResultDto.data.Columns.filter(element => element.FieldTypeID == 10);
    let dateColumns = executionResultDto.data.Columns.filter(element => element.FieldTypeID == 8);

    rows = executionResultDto.data.Records.map(record => {
      const row: { [prop: string]: any } = {};
      Object.keys(record).map(key => {
        if (record[key] !== null && record[key] !== undefined) {
          if (dateTimeColumns.some(val => val.Name == key)) {
            const date = new Date(record[key]);
            row[key] = this.datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
          } else if (dateColumns.some(val => val.Name == key)) {
            const date = new Date(record[key]);
            row[key] = this.datePipe.transform(date, 'dd/MM/yyyy');
          } else if (record[key].toString().toLowerCase() === 'true') {
            row[key] = 'Yes';
          } else if (record[key].toString().toLowerCase() === 'false') {
            row[key] = 'No';
          } else {
            row[key] = record[key];
          }
        }
      });
      return row;
    });
    return rows;
  }

  // Get Rows for Export
  getRowsForExport(executionResultDto: ExecutionResultDto) {
    let rows: { [prop: string]: any }[] = [];
    let dateTimeColumns = executionResultDto.data.Columns.filter(element => element.FieldTypeID == 10);
    let dateColumns = executionResultDto.data.Columns.filter(element => element.FieldTypeID == 8);

    rows = executionResultDto.data.Records.map(record => {
      const row: { [prop: string]: any } = {};
      Object.keys(record).map(key => {
        var propColumn = this._columns.find(col => col.prop == key && col.visible);
        if (propColumn) {
          var friendlyName = propColumn.name.replace(/([a-z])([A-Z])/g, '$1 $2');
          if (this._columns.some(col => col.name === friendlyName)) {
            if (friendlyName.indexOf(',') != -1) friendlyName = friendlyName.replace(/,/g, ''); //remove commas from column names if they are any
            if (record[key] !== null && record[key] !== undefined) {
              if (dateTimeColumns.some(val => val.Name == key)) {
                const date = new Date(record[key]);
                row[friendlyName] = this.datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
              } else if (dateColumns.some(val => val.Name == key)) {
                const date = new Date(record[key]);
                row[friendlyName] = this.datePipe.transform(date, 'dd/MM/yyyy');
              } else if (record[key].toString().toLowerCase() === 'true') {
                row[friendlyName] = 'Yes';
              } else if (record[key].toString().toLowerCase() === 'false') {
                row[friendlyName] = 'No';
              } else if (this.isValidContactNumber(record[key])) {
                row[friendlyName] = `=\"${record[key]}\"`;
              } else {
                row[friendlyName] = record[key];
              }
          } else {
            row[friendlyName] = record[key];
          }
        }
      }
    });
    return row;
  });
  return rows;
  }

  getSecondTableRowsForExport(executionResultDto: ExecutionResultDto) {
    let rows: { [prop: string]: any }[] = [];
    let dateTimeColumns = executionResultDto.data.Columns.filter(element => element.FieldTypeID == 10);
    let dateColumns = executionResultDto.data.Columns.filter(element => element.FieldTypeID == 8);

    rows = executionResultDto.data.Records.map(record => {
      const row: { [prop: string]: any } = {};
      Object.keys(record).map(key => {
        var propColumn = this._secondTableColumns.find(col => col.prop == key && col.visible);
        if (propColumn) {
          var friendlyName = propColumn.name.replace(/([a-z])([A-Z])/g, '$1 $2');
          if (this._secondTableColumns.some(col => col.name === friendlyName)) {
            if (friendlyName.indexOf(',') != -1) friendlyName = friendlyName.replace(/,/g, ''); //remove commas from column names if they are any
            if (record[key] !== null && record[key] !== undefined) {
              if (dateTimeColumns.some(val => val.Name == key)) {
                const date = new Date(record[key]);
                row[friendlyName] = this.datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
              } else if (dateColumns.some(val => val.Name == key)) {
                const date = new Date(record[key]);
                row[friendlyName] = this.datePipe.transform(date, 'dd/MM/yyyy');
              } else if (record[key].toString().toLowerCase() === 'true') {
                row[friendlyName] = 'Yes';
              } else if (record[key].toString().toLowerCase() === 'false') {
                row[friendlyName] = 'No';
              } else if (this.isValidContactNumber(record[key])) {
                row[friendlyName] = `=\"${record[key]}\"`;
              } else {
                row[friendlyName] = record[key];
              }
          } else {
            row[friendlyName] = record[key];
          }
        }
      }
    });
    return row;
  });
  return rows;
  }

  getFinalContactsTableRowsForExport(executionResultDto: ExecutionResultDto) {
    let rows: { [prop: string]: any }[] = [];
    let dateTimeColumns = executionResultDto.data.Columns.filter(element => element.FieldTypeID == 10);
    let dateColumns = executionResultDto.data.Columns.filter(element => element.FieldTypeID == 8);

    rows = executionResultDto.data.Records.map(record => {
      const row: { [prop: string]: any } = {};
      Object.keys(record).map(key => {
        var propColumn = this._finalContactsTableColumns.find(col => col.prop == key && col.visible);
        if (propColumn) {
          var friendlyName = propColumn.name.replace(/([a-z])([A-Z])/g, '$1 $2');
          if (this._finalContactsTableColumns.some(col => col.name === friendlyName)) {
            if (friendlyName.indexOf(',') != -1) friendlyName = friendlyName.replace(/,/g, ''); //remove commas from column names if they are any
            if (record[key] !== null && record[key] !== undefined) {
              if (dateTimeColumns.some(val => val.Name == key)) {
                const date = new Date(record[key]);
                row[friendlyName] = this.datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
              } else if (dateColumns.some(val => val.Name == key)) {
                const date = new Date(record[key]);
                row[friendlyName] = this.datePipe.transform(date, 'dd/MM/yyyy');
              } else if (record[key].toString().toLowerCase() === 'true') {
                row[friendlyName] = 'Yes';
              } else if (record[key].toString().toLowerCase() === 'false') {
                row[friendlyName] = 'No';
              } else if (this.isValidContactNumber(record[key])) {
                row[friendlyName] = `=\"${record[key]}\"`;
              } else {
                row[friendlyName] = record[key];
              }
          } else {
            row[friendlyName] = record[key];
          }
        }
      }
    });
    return row;
  });
  return rows;
  }

  showExportButton() {
    if (this._selectedTabIndex == 0) 
      return !this._finalContactsTableHasRows;
    else if (this._selectedTabIndex == 1)
      return !this._tableHasRows;
    else if (this._selectedTabIndex == 2)
      return !this._secondTableHasRows;
    else
      return true;
  }

  // add ability to dynamically sort data retrieved from the database by providing the direction and column being sorted
  // we also need to ensure that the captured directions are formatted to work with the API and SQL script
  onSort($event) {
    var direction: string;
    if ($event.sorts[0].dir === "desc") {
      direction = "Descending";
    }
    else {
      direction = "Ascending";
    }
    this.getContacts(undefined, direction, $event.sorts[0].prop);
  }

  secondTableOnSort($event) {
    var direction: string;
    if ($event.sorts[0].dir === "desc") {
      direction = "Descending";
    }
    else {
      direction = "Ascending";
    }
    this.getContactsForSecondList(undefined, direction, $event.sorts[0].prop);
  }

  finalContactsTableOnSort($event) {
    var direction: string;
    if ($event.sorts[0].dir === "desc") {
      direction = "Descending";
    }
    else {
      direction = "Ascending";
    }
    this.getFinalContacts(undefined, direction, $event.sorts[0].prop);
  }

  // give table pagination functionality
  // as the sql behind the data does not do this for us
  paginateTable(event?: any) {

    if (event !== undefined) {
      this._pageLimit = event.pageSize;
      this._currentPage = + event.pageIndex + 1;
      this._pageOffset = + event.pageIndex;
    }

    this.paginationDataService.SetChildModelPaginationDataToLocalStorage(this._currentPage, this._pageLimit, this._sortColumn, this._sortDirection, this._pageTitle);
    this.getContacts(this._currentPage);
  }

  paginateSecondTable(event?: any) {

    if (event !== undefined) {
      this._secondTablePageLimit = event.pageSize;
      this._secondTableCurrentPage = + event.pageIndex + 1;
      this._secondTablePageOffset = + event.pageIndex;
    }

    this.paginationDataService.SetSecondListChildModelPaginationDataToLocalStorage(this._secondTableCurrentPage, this._secondTablePageLimit, this._secondTableSortColumn, this._secondTableSortDirection, this._secondTablePageTitle);
    this.getContactsForSecondList(this._secondTableCurrentPage);
  }

  paginateFinalContactsTable(event?: any) {

    if (event !== undefined) {
      this._finalContactsTablePageLimit = event.pageSize;
      this._finalContactsTableCurrentPage = + event.pageIndex + 1;
      this._finalContactsTablePageOffset = + event.pageIndex;
    }

    this.paginationDataService.SetFinalContactsListChildModelPaginationDataToLocalStorage(this._finalContactsTableCurrentPage, this._finalContactsTablePageLimit, this._finalContactsTableSortColumn, this._finalContactsTableSortDirection, this._finalContactsTableTitle);
    this.getFinalContacts(this._finalContactsTableCurrentPage);
  }

  isValidContactNumber(value) {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value);
  }

  closeModal() {
    this.contactPreviewModal.ngOnDestroy();
  }

  onTabChanged($event) {
    this._selectedTabIndex = $event.index;

    if (this._selectedTabIndex == 0) {
        this.getFinalContacts(this._finalContactsTableCurrentPage);
    } if (this._selectedTabIndex == 1) {
        this.getContacts(this._currentPage);
    } else {
        this.getContactsForSecondList(this._secondTableCurrentPage);
    }
  }
}
