export class ABTestReportsModel {
    public HasABData: boolean;
    public SubjectA: string;
    public SubjectB: string;
    public WinnerSubject: string
    public SubjectATotalOpenCount: number;
    public SubjectATotalOpenPercent: number;
    public SubjectBTotalOpenCount: number;
    public SubjectBTotalOpenPercent: number;
    public SubjectATotalSent: number;
    public SubjectATotalSentPercent: number;
    public SubjectBTotalSent: number;
    public SubjectBTotalSentPercent: number;
  
    constructor(init: any) {
      this.SubjectA = init.SubjectA;
      this.SubjectB = init.SubjectB;
      this.WinnerSubject = init.WinnerSubject;
      this.SubjectATotalOpenCount = init.SubjectATotalOpenCount;
      this.SubjectBTotalOpenCount = init.SubjectBTotalOpenCount;
      this.SubjectATotalSent = init.SubjectATotalSent;
      this.SubjectBTotalSent = init.SubjectBTotalSent;
      this.HasABData = init.HasABData;
      this.SubjectATotalOpenPercent = init.SubjectATotalOpenPercent;
      this.SubjectBTotalOpenPercent = init.SubjectBTotalOpenPercent;
      this.SubjectATotalSentPercent = init.SubjectATotalSentPercent;
      this.SubjectBTotalSentPercent = init.SubjectBTotalSentPercent;
    }
  }
  