import { TriggerTargetModel } from './trigger-params/trigger-target-model';
import { ContactsFilterModel } from './trigger-params/contacts-filter-model';
import { SendMessageActionInfo } from './trigger-params/send-message-action-info';
import { NotifyContactsActionInfo } from './trigger-params/notify-contacts-action-info';
import { UpdateContactActionInfo } from './trigger-params/update-contact-action-info';
import { TriggerSchedulingModel } from './trigger-params/trigger-scheduling-model';
import { TriggerEnvironment } from './trigger-params/trigger-environment';
import { ExtractModel } from '../extracts/extract-model';
import { SendViaApiInfo } from './trigger-params/send-via-api-info';
import { SecondListInfoModel } from './trigger-params/second-list-info-model';
import { NotifyContactsUploadActionInfo } from './trigger-params/notify-contacts-upload-action-info';
import { ABTestInfoModel } from './trigger-params/ab-test-info-model';

export class TriggerExecutionDataModel {
  public TriggerTarget: TriggerTargetModel;
  public ContactsFilter: ContactsFilterModel;
  public SendMessage: SendMessageActionInfo;  
  public NotifyContacts: NotifyContactsActionInfo;
  public NotifyContactsUpload: NotifyContactsUploadActionInfo;
  public UpdateContact: UpdateContactActionInfo;
  public Scheduling: TriggerSchedulingModel;
  public Environment: TriggerEnvironment;
  public Extract: ExtractModel;
  public SendViaApi: SendViaApiInfo;
  public SecondListInfo: SecondListInfoModel;
  public ABTestInfo: ABTestInfoModel;

  constructor(init: any) {
    this.TriggerTarget = init.TriggerTarget;
    this.ContactsFilter = init.ContactsFilter;
    this.SendMessage = init.SendMessage;
    this.NotifyContacts = init.NotifyContacts;
    this.UpdateContact = init.UpdateContact;
    this.Scheduling = init.Scheduling;
    this.Environment = init.Environment;
    this.Extract = init.Extract;
    this.SendViaApi = init.SandViaApi;
    this.SecondListInfo = init.SecondListInfo;
    this.ABTestInfo = init.ABTestInfo;
  }
}
