<div class="row mt-4 d-flex justify-content-center">
  <div class="col" style="max-width: 970px;">
    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-2 justify-content-center">

      <!--Card Header-->
      <div class="view view-cascade gradient-card-header primary-color narrower
        py-2 mx-4 mb-3 d-flex justify-content-center">
        <h4 class="page-title">{{_titleText}}</h4>
      </div>
      <!--/Card Header-->

      <!--Card Content-->
      <mdb-card-body cascade="true" class="">

        <section>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-8 offset-2">
                <form [formGroup]="this.triggerDetailsForm">
                  <div class="md-form">
                    <input 
                    mdbInput 
                    type="text" 
                    class="form-control"
                    id="trigger-title"
                    max="500"
                    formControlName="title">
                    <label for="trigger-title">Title</label>
                    <div class="alert alert-warning remove-padding" *ngIf="this.triggerDetailsForm.get('title').invalid">
                      Please enter a title.
                    </div>
                  </div>                  
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mdb-stepper #stepper [linear]="true" [vertical]="true" class="trigger-container">
                  <mdb-step name="This action relates to" [stepForm]="stepForm.get('listForm')">                    
                    <form [formGroup]="stepForm.get('listForm')">

                      <div class="md-form remove-top-margin">
                        <div class="form-check">
                          <input 
                            type="radio" 
                            class="form-check-input" 
                            id="triggerActionType" 
                            [value]="'list'"
                            formControlName="triggerActionType" 
                            name="triggerActionType"
                            label="A trigger against the following list">
                          <label class="form-check-label" for="list-trigger-check-1">A trigger against the following lists</label>
                        </div>
                      </div>
                    
                      <div class="d-flex">
                        <div class="offset" [@slide]>
                          <div class="md-form remove-vertical-margin">
                            <mdb-select
                              [options]="_listFolderDropdown"
                              [filterEnabled]="true"
                              [visibleOptions]="10"
                              label="Select Folder"
                              formControlName="listFolder"
                              (selected)="onListFolderSelected($event)">
                            </mdb-select>
                          </div>
                      
                          <div class="md-form">
                            <mdb-select
                              [options]="_lightListsDropdown"
                              [filterEnabled]="true"
                              [visibleOptions]="10"
                              label="Select List"
                              formControlName="list"
                              (selected)="onListSelected($event)"
                              >
                            </mdb-select>
                            <div class="alert alert-warning remove-padding" *ngIf="this.stepForm.get('listForm').get('list').invalid && 
                                                                                   this.stepForm.get('listForm').get('list').touched">
                              Please select a list.
                            </div>
                          </div>
  
                          <div>
                            <p class="mt-2 text-dark font-weight-bold">And will only occur for contacts with the following criteria...</p>
                          </div>
                          <div class="scrollbar scrollbar-primary force-overflow contact-filter-list" (click)="this.showQueryBuilder()"> 
                            <input type="hidden" #contactFilterCriteria formControlName="contactFilterCriteria" id="contactFilterCriteria">
                            <p *ngIf="contactFilterCriteria.value === ''" class="contact-filter-placeholder remove-bottom-margin">Set Contact Criteria...</p>
                            <p *ngIf="contactFilterCriteria.value !== ''" class="contact-filter-string">{{getContactsFilterSaved()}}</p> 
                          </div>
                          
                          <div class="scrollbar scrollbar-primary force-overflow" [@slide]>
                            <form [formGroup]="stepForm.get('listForm').get('updateValuesFormGroup')">
                              <p class="mt-2 text-dark font-weight-bold">Change the following value(s) in the Contact record...</p>
                              <div *ngIf="this._readableContactValues.length === 0"
                                class="scrollbar scrollbar-primary force-overflow contact-list-empty"
                                (click)="this.showUpdateContactsModal()">
                                <p class="contact-placeholder remove-bottom-margin">Select Contact Values</p>
                              </div>
                              <div *ngIf="this._readableContactValues.length > 0"
                                class="scrollbar scrollbar-primary force-overflow contact-list text-left"
                                (click)="this.showUpdateContactsModal()">
                                <div *ngFor="let values of this._readableContactValues" class="contact-filter-string text-dark">
                                  <span *ngFor="let value of values">
                                    {{value}}
                                  </span>
                                </div>
                              </div>
                            </form>
                          </div>
                          
                        </div>
  
                        <div class="offset" [@slide]>
                          <div class="md-form remove-vertical-margin">
                            <mdb-select
                              [options]="_secondListListFolderDropdown"
                              [filterEnabled]="true"
                              [visibleOptions]="10"
                              label="Select Folder"
                              formControlName="secondListFolder"
                              (selected)="onSecondListFolderSelected($event)">
                            </mdb-select>
                          </div>
                      
                          <div class="md-form">
                            <mdb-select
                              [options]="_secondListLightListsDropdown"
                              [filterEnabled]="true"
                              [visibleOptions]="10"
                              label="Select List"
                              formControlName="secondList"
                              (selected)="onSecondListSelected($event)"
                              >
                            </mdb-select>
                            <div class="alert alert-warning remove-padding" *ngIf="this.stepForm.get('listForm').get('secondList').invalid && 
                                                                                   this.stepForm.get('listForm').get('secondList').touched">
                              Please select a list.
                            </div>
                          </div>
  
                          <div>
                            <p class="mt-2 text-dark font-weight-bold">And will only occur for contacts with the following criteria...</p>
                          </div>
                          <div class="scrollbar scrollbar-primary force-overflow contact-filter-list" (click)="this.secondListShowQueryBuilder()"> 
                            <input type="hidden" #secondListContactFilterCriteria formControlName="secondListContactFilterCriteria" id="secondListContactFilterCriteria">
                            <p *ngIf="secondListContactFilterCriteria.value === ''" class="contact-filter-placeholder remove-bottom-margin">Set Contact Criteria...</p>
                            <p *ngIf="secondListContactFilterCriteria.value !== ''" class="contact-filter-string">{{secondListGetContactsFilterSaved()}}</p> 
                          </div>
  
                          <div class="scrollbar scrollbar-primary force-overflow" [@slide]>
                            <form [formGroup]="stepForm.get('listForm').get('secondListUpdateValuesFormGroup')">
                              <p class="mt-2 text-dark font-weight-bold">Change the following value(s) in the Contact record...</p>
                              <div *ngIf="this._secondListReadableContactValues.length === 0"
                                class="scrollbar scrollbar-primary force-overflow contact-list-empty"
                                (click)="this.secondListShowUpdateContactsModal()">
                                <p class="contact-placeholder remove-bottom-margin">Select Contact Values</p>
                              </div>
                              <div *ngIf="this._secondListReadableContactValues.length > 0"
                                class="scrollbar scrollbar-primary force-overflow contact-list text-left"
                                (click)="this.secondListShowUpdateContactsModal()">
                                <div *ngFor="let values of this._secondListReadableContactValues" class="contact-filter-string text-dark">
                                  <span *ngFor="let value of values">
                                    {{value}}
                                  </span>
                                </div>
                              </div>
                            </form>
                          </div>
                          
                        </div>
                      </div>

                    </form>
                  </mdb-step> 

                  <mdb-step name="Send the Contact the Message" [stepForm]="stepForm.get('messageForm')">
                    <form [formGroup]="stepForm.get('messageForm')">
                      <div class="shorten">  
                        <div class="offset" [@slide]>
                          <form [formGroup]="stepForm.get('messageForm')">
                            <p class="text-dark" style="padding-bottom: 10px;">Select type of message</p>

                            <div class="form-check form-check-inline">
                              <input type="radio" class="form-check-input" id="send-message-email-type" name="messageType"
                                formControlName="messageType" [value]="1">
                              <label class="form-check-label" for="send-message-email-type">Email</label>
                            </div>
                    
                            <div class="form-check form-check-inline">
                              <input type="radio" class="form-check-input" id="multi-send-message-email-type-a-b" name="messageType"
                                formControlName="messageType" [value]="3">
                              <label class="form-check-label" for="multi-send-message-email-type-a-b">Email (A/B)</label>
                            </div>

                            <div class="form-check form-check-inline">
                              <input type="radio" class="form-check-input" id="send-message-sms-type" name="messageType"
                                formControlName="messageType" [value]="2">
                              <label class="form-check-label" for="send-message-sms-type">SMS</label>
                            </div>
                    
                            <div class="md-form">
                              <mdb-select [options]="_messageFoldersDropdown" [filterEnabled]="true" [visibleOptions]="10"
                                label="Select Folder" formControlName="messageFolder" (selected)="onMessageFolderSelected($event)">
                              </mdb-select>
                            </div>
                            <div class="md-form">
                              <div class="row">
                                <div class="col-10">
                                  <mdb-select [options]="_notifyMessageDropdown" [filterEnabled]="true" [visibleOptions]="10"
                                    label="Select Message" formControlName="message">
                                  </mdb-select>
                                </div>
                                <div class="col-2">
                                  <a *ngIf="this._selectedMessageId > 0" (click)="showSendContactsMessagePreviewModal()"
                                    mdbTooltip="preview message" placement="top">
                                    <mdb-icon far icon="eye" size="lg"></mdb-icon>
                                  </a>
                                </div>
                              </div>
                              <div class="alert alert-warning remove-padding"
                                *ngIf="this.stepForm.get('messageForm').get('message').invalid && 
                                       this.stepForm.get('messageForm').get('message').touched">
                                This field is required.</div>
                            </div>                            
                          </form>

                          <div [@slide]>
                            <form [formGroup]="stepForm.get('messageForm')">                          
                              <div class="cc-option-container">          
                                <div class="cc-middle-text">
                                  <p class="text-dark text-center align-middle mt-3 font-weight-bold">at</p>
                                </div>
                                <div class="md-form remove-vertical-margin oos-dateTime-right-option">
                                  <input 
                                    mdbInput 
                                    type="datetime-local" 
                                    class="form-control custom-date-time-height"
                                    formControlName="time"/>
                                </div>                                
                              </div>
                              <div class="alert alert-warning remove-padding"
                                  *ngIf="this.stepForm.get('messageForm').get('time').invalid && 
                                       this.stepForm.get('messageForm').get('time').touched">
                                This field is required.</div>
                            </form>  
                          </div>
    
                        </div> 
                    
                      </div>

                    </form>    
                      
                  </mdb-step>
                  <mdb-step *ngIf="this._showMultiABTestStep" name="Email (A/B Testing) actions" [stepForm]="stepForm.controls.abTestingFormGroup">
                    <form [formGroup] = "stepForm.controls.abTestingFormGroup">
                      <div class="container">
                        <div class="row">
                          <div class="col-md-10">
                            <label class="label-style-bold" for="multiTriggerMessageSubjectA">Subject A</label>
                            <input type="text" id="multiTriggerMessageSubjectA" class="form-control input-style" formControlName="multiTriggerMessageSubjectA" readonly>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-10">
                            <label class="label-style-bold" for="multiTriggerMessageSubjectB">Subject B</label>
                            <input type="text" id="multiTriggerMessageSubjectB" class="form-control input-style" formControlName="multiTriggerMessageSubjectB">
                          </div>
                        </div>
            
                        <div class="row margin-top-20">
                          <div class="col-md-auto">
                            <label class="label-style-bold">Test Metric</label>
                          </div>
          
                          <div class="col-md-auto">
                            <label class="label-style-regular">Open Rate</label>
                          </div>       
                        </div>
            
                        <div class="row margin-top-20">
                          <div class="col-md-auto">
                            <label class="label-style-bold">Test Length</label>
                          </div>
          
                          <div class="col-md-auto">
                            <input type="number" id="triggerAbTestLength" class="form-control input-style" formControlName="multiTriggerAbTestLength" min="1" (ngModelChange)="onMultiTriggerAbTestLengthChange($event)"/>
                          </div>
        
                          <div class="col-md-auto">
                            <select class="form-control select-input"
                                    name="multiTriggerAbTestLengthType"
                                    id="multiTriggerAbTestLengthType" 
                                    formControlName="multiTriggerAbTestLengthType">
                              <option value=1>{{ _multiTriggerAbTestLengthTypeHours }}</option>
                              <option value=2>{{ _multiTriggerAbTestLengthTypeDays }}</option>
                            </select>
                          </div>
        
                          <div class="col-md-auto">
                            <label class="label-style-regular">We recommend you test for at least 4 hours</label>
                          </div>                         
                        </div>
            
                        <div class="row margin-top-20">
                          <div class="col-md-10">
                            <label class="label-style-bold">Contact List Test Size</label>
                          </div>
                        </div>

                        <div class="row">                          
                          <div class="col-md-10">                            
                            <mdb-range-input class="range-field" min="10" max="100" step="10" formControlName="multiAbTestSize" (rangeValueChange)="onMultiTriggerTestSizeValueChange($event)"></mdb-range-input>
                          </div>
        
                          <div class="col-md-2">
                            <label class="label-style-regular">{{_selectedMultiTriggerABTestSize}}%</label> 
                          </div>                           
                        </div>
  
                        <div class="row">
                          <div class="col-md-auto">
                            <label class="label-style-bold">{{_listName}}</label>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-auto">                            
                            <label class="label-style-regular">A Recipients {{_subjectACount}} ({{_subjectAPercent}}%), B Recipients {{_subjectBCount}} ({{_subjectBPercent}}%), Winner Recipients {{_winningCount}} ({{_winningPercent}}%)</label> 
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-auto">
                            <label class="label-style-bold">{{_secondListName}}</label>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-auto">                            
                            <label class="label-style-regular">A Recipients {{_secondListSubjectACount}} ({{_subjectAPercent}}%), B Recipients {{_secondListSubjectBCount}} ({{_subjectBPercent}}%), Winner Recipients {{_secondListWinnerCount}} ({{_winningPercent}}%)</label> 
                          </div>
                        </div>

                        <div class="row margin-top-20">
                          <div class="col-md-10">
                            <div class="alert alert-warning justify-content-center" role="alert" *ngIf="_showWrongSizeOrContactsCountWarning || _secondListShowWrongSizeOrContactsCountWarning">
                              No contacts will be sent the A/B test message. Either increase the Test Size or add more recipients.
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </mdb-step>
                  <mdb-step name="Preview Data and Message for Send" [stepForm]="stepForm">

                    <app-preview-data-message-multi-list #previewDataMessageComponent 
                      [parentStepForm]="this.stepForm"
                      [listID]="this.stepForm.get('listForm').get('list').value"
                      [filterContactRules]="this.stepForm.get('listForm').get('contactFilterCriteria').value"
                      [secondListID]="this.stepForm.get('listForm').get('secondList').value"
                      [secondListFilterContactRules]="this.stepForm.get('listForm').get('secondListContactFilterCriteria').value"
                      [listFolder]="this.stepForm.get('listForm').get('listFolder').value"
                      [secondListFolder]="this.stepForm.get('listForm').get('secondListFolder').value"
                      [messageID]="this._selectedMessageId"
                      [listName] = "this._listName"
                      [secondListName] = "this._secondListName"
                    >
                    </app-preview-data-message-multi-list> 

                  </mdb-step>
                  
                </mdb-stepper>

                <div class="row">
                  <div class="col-md-12 text-center">                    
                    <button type="button" mdbBtn color="primary" class="rounded" [disabled]="this.stepForm.get('listForm').invalid || this.stepForm.get('messageForm').invalid || this.triggerDetailsForm.invalid || this.stepForm.get('abTestingFormGroup').invalid || _showWrongSizeOrContactsCountWarning || _secondListShowWrongSizeOrContactsCountWarning" (click)="saveMultiListTrigger()">Save</button>
                    <button type="button" mdbBtn color="secondary" class="rounded" (click)="onCancelClicked()">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </mdb-card-body>
      <!--/Card Content-->
    </mdb-card>
    <!--/Card-->
  </div>
</div>

<div mdbModal #messagePreviewModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="messagePreviewModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 text-dark" id="modalLabel">Message Preview</h4>
      </div>
      <div class="modal-body">
        <div class="scrollbar scrollbar-primary vh70">
          <div *ngIf="_messageTypeNum == 1" class="animated fadeIn desktop-view force-overflow"
            [innerHTML]="_messagePreviewHtml | sanitizeHtml"></div>
          <div *ngIf="_messageTypeNum == 2" class="phone-container">
            <div class="iphone">
              <div class="border">
                <form class="chat">
                  <span></span>
                  <div class="messages" id="adobewordpress">
                    <div class="message">
                      <div class="fromThem">
                        <p>{{this._smsMessageContent}}</p>
                        <date><b></b>{{this._smsMessageContentDate | date: 'dd/MM/yyyy H:mm a'}}</date>
                      </div>
                    </div>
                  </div>
                  <input type="text" placeholder="Your message">
                  <input type="submit" value="Send">
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="secondary" mdbWavesEffect
          (click)="messagePreviewModal.hide()">Close</button>
      </div>
    </div>
  </div>
</div>

<div mdbModal #queryBuilderModal="mdbModal" class="modal fade modal-overflow" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="queryBuilderModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">Filter Contacts</h4>
      </div>
      <div class="modal-body">                
        <app-query-builder 
          #queryBuilder
          [_parentListID]="this._selectedListId" 
          [_parentQuery]="this._selectedContactFilterCriteria"
          [_parentSource]="this._queryBuilderParentSource"
          (queryBuilderSave)="this.contactsFilterSave($event)"
          [(_parentPageTitle)]="this._pageTitleForQueryBuilder">            
        </app-query-builder>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>

<div mdbModal #updateContactValueModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="updateContactValueModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 text-dark" id="myModalLabel">Update Contact Values</h4>
      </div>
      <div class="modal-body">
        <app-update-contacts #updateContactsComponent>
        </app-update-contacts>
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="secondary" mdbWavesEffect
          (click)="updateContactValueModal.hide()">Done</button>
      </div>
    </div>
  </div>
</div>

<div mdbModal #secondListUpdateContactValueModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="secondListUpdateContactValueModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 text-dark" id="myModalLabel">Update Contact Values</h4>
      </div>
      <div class="modal-body">
        <app-update-contacts-second-list #secondListUpdateContactsComponent>
        </app-update-contacts-second-list>
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="secondary" mdbWavesEffect
          (click)="secondListUpdateContactValueModal.hide()">Done</button>
      </div>
    </div>
  </div>
</div>