import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageReportsService } from 'src/app/_services/messaging/messages/message-reports.service';
import {
  MessageReportModel
} from 'src/app/_models/messaging/messages/message-reports/email-reports/message-report-model';
import {
  MessageReportModelAdapter
} from 'src/app/_models/messaging/messages/message-reports/email-reports/message-report-model.adapter';
import { ListsService } from 'src/app/_services/messaging/lists-and-contacts/lists/lists.service';
import {
  MessageReportLinkClickModelAdapter
} from 'src/app/_models/messaging/messages/message-reports/email-reports/message-report-link-click-model.adapter';
import jsPDF from 'node_modules/jspdf';
import html2canvas from 'node_modules/html2canvas';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { NotificationService } from 'src/app/_services/notification.service';
import {
  SMSMessageReportModel
} from 'src/app/_models/messaging/messages/message-reports/sms-reports/sms-message-report-model';
import {
  SMSMessageReportModelAdapter
} from 'src/app/_models/messaging/messages/message-reports/sms-reports/sms-message-report-model.adapter';
import {
  SMSRepliesModelAdapter
} from '../../../../../_models/messaging/messages/message-reports/sms-reports/sms-replies-model.adapter';
import {
  MessageReportLinkClickModel
} from '../../../../../_models/messaging/messages/message-reports/email-reports/message-report-link-click-model';
import { DateService } from '../../../../../_services/system/date.service';
import {
  SMSRepliesModel
} from '../../../../../_models/messaging/messages/message-reports/sms-reports/sms-replies-model';
import { ITableColumns } from '../../../../../_models/tables/table-interfaces';
import { TableService } from '../../../../../_services/tables/table.service';
import { PaginationDataService } from '../../../../../_services/tables/pagination-data/pagination-data.service';
import { ABTestReportsModelAdapter } from 'src/app/_models/messaging/messages/message-reports/email-reports/a-b-test-reports-model.adapter';
import { ABTestReportsModel } from 'src/app/_models/messaging/messages/message-reports/email-reports/a-b-test-reports-model';

@Component({
  selector: 'app-message-report-details',
  templateUrl: './message-report-details.component.html',
  styleUrls: ['./message-report-details.component.scss']
})
export class MessageReportDetailsComponent implements OnInit {
  messageType: MessageTypeEnum;
  messageTypeEnumEmail = MessageTypeEnum.email;
  messageTypeEnumSMS = MessageTypeEnum.sms;
  reportID: number;
  emailReport: MessageReportModel;
  abTestReport: ABTestReportsModel;
  smsReport: SMSMessageReportModel;
  listName: string;
  downloadingPDF = false;
  displayedColumns: ITableColumns[];
  columnsToDisplay: string[];
  data: any[];
  length: number;
  pageLimit = 10;
  currentPage = 1;
  pageCount = 0;
  pageOffset = 0;
  sortColumn: string = 'UpdatedDate';
  sortDirection: string = 'Descending';
  titlePage: string = "Email Reports";
  showABTestBlock: boolean = false;

  clickedLinks: MessageReportLinkClickModel[];
  paginatedClickedLinks: MessageReportLinkClickModel[][] = [];

  // Delivered Chart Stats
  deliveredChartType = 'pie';
  deliveredChartDatasets: Array<any> = [{data: [], label: '%'}];
  deliveredChartLabels: Array<string> = [];
  deliveredChartColors: Array<any> = [
    {
      backgroundColor: ['#1d1757', '#5fcae1', '#16aceb', '#fc8800', '#ffb134'],
      hoverBackgroundColor: ['#261f69', '#70d1e6', '#2cb7f2', '#ff9c29', '#ffbd54'],
      borderWidth: 2,
    }
  ];
  deliveredChartOptions: any = {
    responsive: true
  };

  // Email Opened Chart Stats
  emailOpenedChartType = 'pie';
  emailOpenedChartDatasets: Array<any> = [{data: [], label: 'label'}];
  emailOpenedChartLabels: Array<string> = [];
  emailOpenedChartColors: Array<any> = [
    {
      backgroundColor: ['#1d1757', '#5fcae1'],
      hoverBackgroundColor: ['#261f69', '#70d1e6'],
      borderWidth: 2,
    }
  ];
  emailOpenedChartOptions: any = {
    responsive: true
  };

  // Clicked Chart Stats
  clickedChartType = 'pie';
  clickedChartDatasets: Array<any> = [{data: [], label: 'label'}];
  clickedChartLabels: Array<string> = [];
  clickedChartColors: Array<any> = [
    {
      backgroundColor: ['#1d1757', '#5fcae1', '#16aceb', '#fc8800'],
      hoverBackgroundColor: ['#261f69', '#70d1e6', '#2cb7f2', '#ff9c29'],
      borderWidth: 2,
    }
  ];
  clickedChartOptions: any = {
    responsive: true
  };

  // SMS Opened Chart Stats
  smsOpenedChartType = 'pie';
  smsOpenedChartDatasets: Array<any> = [{data: [], label: 'label'}];
  smsOpenedChartLabels: Array<string> = [];
  smsOpenedChartColors: Array<any> = [
    {
      backgroundColor: ['#1d1757', '#5fcae1', '#16aceb', '#fc8800', '#ffb134'],
      hoverBackgroundColor: ['#261f69', '#70d1e6', '#2cb7f2', '#ff9c29', '#ffbd54'],
      borderWidth: 2,
    }
  ];
  smsOpenedChartOptions: any = {
    responsive: true
  };

  @ViewChild('pdfContainer') pdfContainer: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private messageReportsService: MessageReportsService,
    private messageReportModelAdapter: MessageReportModelAdapter,
    private listsService: ListsService,
    private messageReportLinkClickModelAdapter: MessageReportLinkClickModelAdapter,
    private router: Router,
    private notificationService: NotificationService,
    private smsMessageReportModelAdapter: SMSMessageReportModelAdapter,
    private smsRepliesModelAdapter: SMSRepliesModelAdapter,
    private dateService: DateService,
    private tableService: TableService,
    private childPaginationDataService: PaginationDataService,
    private abTestReportsModelAdapter: ABTestReportsModelAdapter
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.reportID = params.reportID;
    });

    this.activatedRoute.data.subscribe(result => {

      if (result.page === 'MessagesReportsEmail') {
        this.displayedColumns = [
          {name: 'link'}
        ];
        this.columnsToDisplay = ['link'];
        this.initEmailReport();
      } else if (result.page === 'MessagesReportsSMS') {
        this.displayedColumns = [
          {name: 'smsReplyId', displayName: 'SMSReply ID'},
          {name: 'replyDate', displayName: 'Reply Date'},
          {name: 'smsMessage', displayName: 'SMS Message Body'},
          {name: 'number', displayName: 'Number'}
        ];
        this.columnsToDisplay = ['smsReplyId', 'replyDate', 'smsMessage', 'number'];
        this.initSMSReports();
      } else {
        this.router.navigate(['dashboard']);
        this.notificationService.showWarning('Error retrieving the desired Report Type. Please contact an Administrator.');
      }
    });
  }

  initEmailReport(): void {
    this.messageType = MessageTypeEnum.email;
    this.getEmailReportDetails();
    this.getABTestReportDetails();
  }

  initSMSReports(): void {
    this.messageType = MessageTypeEnum.sms;
    this.getSMSReportDetails();
  }

  getEmailReportDetails(): void {
    this.emailReport = this.messageReportModelAdapter.createEmpty();
    this.messageReportsService.getEmailReportDetails(this.reportID).subscribe(result => {
      this.emailReport = this.messageReportModelAdapter.adapt(result.data);
      this.getListName();
      this.populateDeliveredChart();
      this.populateEmailOpenedChart();
      this.populateClickedChart();
      this.tableService.requestTableData();
    });
  }

  getABTestReportDetails(): void {
    this.abTestReport = this.abTestReportsModelAdapter.createEmpty();
    this.messageReportsService.getABTestReportDetails(this.reportID).subscribe(result => {
      this.abTestReport = this.abTestReportsModelAdapter.adapt(result.data); 
      console.log(this.abTestReport);
      this.showABTestBlock = this.abTestReport.HasABData;
    });
  }

  getSMSReportDetails(): void {
    this.smsReport = this.smsMessageReportModelAdapter.createEmpty();
    this.messageReportsService.getSMSReportDetails(this.reportID).subscribe(result => {
      this.smsReport = this.smsMessageReportModelAdapter.adapt(result.data);
      this.listName = this.smsReport.ListName;
      this.populateSMSOpenedChart();
      this.tableService.requestTableData();
    });
  }

  getListName(): void {
    this.listsService.getListById(this.emailReport.ListID).subscribe(result => {
      if (result.data[0]) {
        this.listName = result.data[0].ListName;
      }
    });
  }

  populateDeliveredChart(): void {
    const unknown: number = 1 - (this.emailReport.DeliveryRate +
      this.emailReport.FailRate +
      this.emailReport.BounceRate +
      this.emailReport.TemporaryBounceRate);

    this.deliveredChartDatasets[0].data.push(
      (this.emailReport.DeliveryRate * 100).toFixed(2),
      (this.emailReport.FailRate * 100).toFixed(2),
      (this.emailReport.BounceRate * 100).toFixed(2),
      (this.emailReport.TemporaryBounceRate * 100).toFixed(2),
      (unknown * 100).toFixed(2),
    );

    this.deliveredChartLabels = [
      'Delivered', 'Failed', 'Bounced', 'Temp Bounced', 'Queued'
    ];
  }

  populateEmailOpenedChart(): void {
    this.emailOpenedChartDatasets[0].data.push(
      (this.emailReport.OpenRate * 100).toFixed(2),
      (this.emailReport.UnopenRate * 100).toFixed(2),
    );

    this.emailOpenedChartLabels = ['Opened', 'Unopened'];
  }

  populateSMSOpenedChart(): void {
    const unknown: number = ((this.smsReport.TotalSent -
        (this.smsReport.ActivityDelivered +
          this.smsReport.ActivityUndelivered +
          this.smsReport.ActivityBounced +
          this.smsReport.ActivityPending)) /
      this.smsReport.TotalSent);

    this.smsOpenedChartDatasets[0].data.push(
      ((this.smsReport.ActivityDelivered / this.smsReport.TotalSent) * 100).toFixed(2),
      ((this.smsReport.ActivityUndelivered / this.smsReport.TotalSent) * 100).toFixed(2),
      ((this.smsReport.ActivityBounced / this.smsReport.TotalSent) * 100).toFixed(2),
      ((this.smsReport.ActivityPending / this.smsReport.TotalSent) * 100).toFixed(2),
      (unknown * 100).toFixed(2),
    );

    this.smsOpenedChartLabels = ['Delivered', 'Undelivered', 'Bounced', 'Pending', 'Unknown'];
  }

  populateClickedChart(): void {
    const profileUpdated = (this.emailReport.ProfileUpdatedRate).toFixed(2);
    const otherClick = this.emailReport.ProfileUpdatedRate > 0
      ? ((this.emailReport.ClickedRate - this.emailReport.ProfileUpdatedRate)).toFixed(2)
      : 0;
    const clicked = ((this.emailReport.Opened / this.emailReport.Clicked) * 100).toFixed(2);
    const notClicked = (100 - +clicked).toFixed(2);
    this.clickedChartDatasets[0].data.push(profileUpdated, otherClick, clicked, notClicked);
    this.clickedChartLabels = ['Profile Updated', 'Other Click', 'Clicked', 'Not Clicked'];
  }

  downloadAsPDF(): void {
    this.downloadingPDF = true;

    window.setTimeout(() => {
      html2canvas(this.pdfContainer.nativeElement, {
        allowTaint: true,
        scale: 1
      }).then((canvas) => {
        const imgWidth = 205;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const img = canvas.toDataURL('image/png');
        const doc = new jsPDF('p', 'mm', 'a4');
        const positionY = 0;
        const positionX = 0;
        doc.addImage(img, 'PNG', positionX, positionY, imgWidth, imgHeight);
        doc.save('Report-Details.pdf');
      });
      this.downloadingPDF = false;
    }, 50);
  }

  // allows the user to select a filter from the front end when clicking a number
  // and take them to the next page that displays the numbers in a table
  drillDownReport(filter: string): void {
    if (filter.toLowerCase().indexOf('email') > -1) {
      this.router.navigate(['email-reports/details/' + this.reportID + '/', filter]);
    } else {
      this.router.navigate(['sms-reports/details/' + this.reportID + '/', filter]);
    }
  }

  // return to the previous page
  goBack(): void {
    this.childPaginationDataService.SetChildPaginationDataToLocalStorage
      (this.currentPage, this.pageLimit, this.sortColumn, this.sortDirection, this.titlePage);
    this.router.navigate([this.router.url.split('/')[1]]);
  }

  getPaginatedClickedLinks(): void {
    const chunkSize = this.pageLimit;
    this.paginatedClickedLinks = [];
    for (let i = 0; i < this.clickedLinks.length; i += chunkSize) {
      const chunk = this.clickedLinks.slice(i, i + chunkSize);
      this.paginatedClickedLinks.push(chunk);
    }
  }

  formatDates(smsReplies: SMSRepliesModel[]): void {
    smsReplies.forEach((reply: SMSRepliesModel) => {
      reply.replyDate = this.dateService.formatStringDate(reply.replyDate);
    });
  }

  getData(data?): void {
    if (this.messageType != MessageTypeEnum.email) {
      const options = {
        ReportID: this.reportID,
        PagerModel: {
          Page: data.currentPage,
          PageSize: data.pageLimit,
          SortColumn: data.sortColumn,
          SortDirection: data.sortDirection
        }
      };

      this.messageReportsService.getSMSReplies(options).subscribe(result => {
        this.data = this.smsRepliesModelAdapter.adaptArray(result.data.Item1);
        this.formatDates(this.data);
        this.length = result.data.Item2;
      });
    } else {
      this.messageReportsService.getClickedLinks(this.reportID).subscribe(result => {
        this.clickedLinks = result.data.Item1;
        this.length = result.data.Item2;
        this.pageLimit = data.pageLimit;

        this.getPaginatedClickedLinks();
        this.data = this.paginatedClickedLinks[data.currentPage - 1];
      });
    }
  }
}
