import { Injectable } from '@angular/core';
import { ABTestReportsModel } from './a-b-test-reports-model';

@Injectable({
  providedIn: 'root'
})

export class ABTestReportsModelAdapter {
  adapt(item: any): ABTestReportsModel {
    return new ABTestReportsModel(item);
  }

  adaptArray(init: Array<any>): ABTestReportsModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new ABTestReportsModel(table))
      : [new ABTestReportsModel(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new ABTestReportsModel({
        SubjectA: '',
        SubjectB: '',
        WinnerSubject: '',
        SubjectATotalOpenCount: 0,
        SubjectBTotalOpenCount: 0,
        SubjectATotalSent: 0,
        SubjectBTotalSent: 0,
        HasABData: false,
        SubjectATotalOpenPercent: 0,
        SubjectBTotalOpenPercent: 0,
        SubjectATotalSentPercent: 0,
        SubjectBTotalSentPercent: 0
    });
  }
}
